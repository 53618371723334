<template>
  <div id="app">
    <div>
      <LoadingOverlay />
      <div role="banner">
        <Ucbar />
        <Header></Header>
      </div>
      <div class="container container-sm">
        <div role="main">
          <Breadcrumb />
          <router-view />
        </div>
      </div>
      <Footer></Footer>
    </div>
  </div>
</template>
<script>
import Header from "./components/Header";
import Footer from "./components/Footer";
import Breadcrumb from "./components/Breadcrumb";
import LoadingOverlay from "./views/LoadingOverlay";

import Ucbar from "./components/Ucbar";

export default {
  name: "App",

  components: {
    Header,
    Footer,
    Breadcrumb,
    LoadingOverlay,
    Ucbar,
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Roboto, sans-serif !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.message_design {
  background-color: #0f8575;
  color: white;
  padding: 10px;
  margin: 10px 0;
}

select {
  height: 38px;
}

.highlightText {
  background: yellow !important;
}

*:focus {
  outline: none !important;
  box-shadow: 0px 0px 2px 3px #1fa885 !important;
  border-color: #0f8575 !important;
}

*:active {
  outline: none !important;
}

/* button:focus,:active {
  background: #ffffff !important;
  color: #09645f !important;
} */

.btn:active {
  outline: none !important;
}

.company-item:hover {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.07);
}

*input,
button,
:active {
  outline: none !important;
}

*button:active {
  outline: none !important;
}

#app {
  background: #ffffff;
  background-repeat: no-repeat !important;
  background: linear-gradient(0deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(0, 148, 128, 0.15) 83%);
  background-size: 100% 400px;
}
</style>

<style>
th {
  font-weight: 500;
}

.text-danger {
  color: #dc2f45 !important;
}

.data-provider {
  background-color: #fbe2c7;
  display: inline-block;
  border-radius: 4px;

  font-weight: 520;
  font-size: 12px;
  line-height: 14px;

  align-items: center;
  text-transform: uppercase;
  color: #8b4723;
  margin: 0px 0px;
  padding: 4px;
  text-decoration: none;
}

.suspended_company {
  background-color: #a06e6e;
  display: inline-block;
  border-radius: 4px;

  font-weight: 520;
  font-size: 12px;
  line-height: 14px;

  align-items: center;
  text-transform: uppercase;
  color: #ffffff;
  width: 80px;
  text-align: center;
  margin: 0px 0px;
  padding: 4px;
  text-decoration: none;
  margin-right: 5px;
}

.active_company {
  background-color: #0f8575;
  display: inline-block;
  border-radius: 4px;

  font-weight: 520;
  font-size: 12px;
  line-height: 14px;

  align-items: center;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0px 0px;
  padding: 4px;
  text-decoration: none;
  margin-right: 5px;
  width: 80px;
  text-align: center;
}

.inactive_company {
  background-color: #707070;
  display: inline-block;
  border-radius: 4px;

  font-weight: 520;
  font-size: 12px;
  line-height: 14px;

  align-items: center;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0px 0px;
  padding: 4px;
  text-decoration: none;
  margin-right: 5px;
  width: 80px;
  text-align: center;
}

.industry-group {
  background-color: #fad6f5;
  display: inline-block;
  border-radius: 4px;

  font-weight: 520;
  font-size: 12px;
  line-height: 14px;

  align-items: center;
  text-transform: uppercase;
  color: #575757;
  margin: 0px 0px;
  padding: 4px;
  text-decoration: none;
}

.other-org {
  background-color: #c0ffc0;
  display: inline-block;
  border-radius: 4px;

  font-weight: 520;
  font-size: 12px;
  line-height: 14px;

  align-items: center;
  text-transform: uppercase;
  color: #4f4f4f;
  margin: 0px 0px;
  padding: 4px;
  text-decoration: none;
}

.data-recipient {
  background-color: #d8f3f8;
  display: inline-block;
  border-radius: 4px;

  font-weight: 520;
  font-size: 12px;
  line-height: 14px;

  align-items: center;
  text-transform: uppercase;
  color: #097690;
  margin: 0px 0px;
  padding: 4px;
  text-decoration: none;
}

.data-access-platform {
  background-color: #f1e5f1;
  display: inline-block;
  border-radius: 4px;

  font-weight: 520;
  font-size: 12px;
  line-height: 14px;

  align-items: center;
  text-transform: uppercase;
  color: #903e97;
  margin: 0px 0px;
  padding: 4px;
  text-decoration: none;
}

.fdx-version-num {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  color: #09645f;
  padding: 4px 6px;
  background: #def8f3;
  border-radius: 16px;
  margin-right: 10px;
}

.fdx-version-in {
  display: inline-block;
  margin-bottom: 5px;
}

.custom-radio {
  float: left;
  margin-right: 3rem;
}

.custom-control-label {
  margin-left: 0.5rem;
}

.btn-success {
  background-color: #0f8575;
}

.btn-success:hover {
  background: #0e7568;
  color: #ffffff !important;
  border: 1px solid #0e7568;
}

.table>thead th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
  font-weight: bold;
}

span.sr-only {
  display: none;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff !important;
  background-color: #097690 !important;
  border-color: #097690 !important;
}

.btn-outline-success:hover {
  background-color: #0e7568;
  border-color: #0e7568;
}

.page-link {
  color: #09645f !important;
}

.multiselect-clear {
  display: none !important;
}
</style>
