import api from "../../api/FdxCertificationPortalApi";
import Toast from "../../components/Notifications/Toast"
import router from '../../router/Router';
// import Error from '../../components/Notifications/ErrorHandler';

// import axios from 'axios';

const vueState = {
    showLoadingOnApiCall: false,
    userData: null,
    newApplicationData: null,
    applicationList: null,
    createdApplicationData: null,
    applicationDetails: null,
    dockerFileDetails: null,
    downloadFileURL: null,
    applicationsDetails: null,
    viewUploadedResults: null,
    activeUseCase: null,
    applicationListByFilter: null,
    applicationUserDetails: null,
}

const getters = {
    getNewApplicationData(state) {
        return state.newApplicationData;
    },
    getUserData(state) {
        return state.userData;
    },
    getApplicationList(state) {
        return state.applicationList;
    },
    getCreatedApplicationData(state) {
        return state.createdApplicationData;
    },
    getApplicationDetails(state) {
        return state.applicationDetails;
    },
    getDockerFileDetails(state) {
        return state.dockerFileDetails;
    },
    getDownloadFileURL(state) {
        return state.downloadFileURL;
    },
    getViewUploadedResults(state) {
        return state.viewUploadedResults;
    },
    getActiveUsecase(state) {
        return state.activeUseCase;
    },
    getApplicationListByFilter(state) {
        return state.applicationListByFilter;
    },
    getApplicationUserDetails(state) {
        return state.applicationUserDetails;
    },
}
// const defaultLogo = 'https://storage.googleapis.com/companylogos/default-logo.png';

const mutations = {
    setUserData(state, userData) {
        state.userData = userData;
    },
    setNewApplicationData(state, newApplicationData) {
        state.newApplicationData = newApplicationData;
    },
    setApplicationList(state, applicationList) {
        state.applicationList = applicationList;
    },
    setCreatedApplicationData(state, createdApplicationData) {
        state.createdApplicationData = createdApplicationData;
    },
    setApplicationDetails(state, applicationDetails) {
        state.applicationDetails = applicationDetails;
    },
    setDockerFileDetails(state, dockerFileDetails) {
        state.dockerFileDetails = dockerFileDetails;
    },
    setDownloadFileURL(state, URL) {
        state.downloadFileURL = URL;
    },
    setViewUploadedResults(state) {
        return state.viewUploadedResults;
    },
    setActiveUseCase(state, activeUseCase) {
        return state.activeUseCase = activeUseCase;
    },
    setApplicationListByFilter(state, applicationListByFilter) {
        return state.applicationListByFilter = applicationListByFilter;
    },
    setApplicationUserDetails(state, applicationUserDetails) {
        state.applicationUserDetails = applicationUserDetails;
    },
    setShowLoadingOnApiCall(state, showLoadingOnApiCall) {
        state.showLoadingOnApiCall = showLoadingOnApiCall;
    },
}

const actions = {

    async getOrganization({ commit, dispatch }, id) {
        if (id) {
            await api.getOrganization(id).then(response => {
                if (response?.status === 200 && response?.data) {
                    commit('setUserData', response?.data);
                }
            }).catch(error => {
                if(error?.response?.status == 400 ||error?.response?.status == 401 ||error?.response?.status == 403 ||error?.response?.status == 404){
                    dispatch("ssoSignout", {root:true});
                }else if(error?.response?.status == 500){
                    commit("setShowLoadingOnApiCall", false);
                    Toast.showToast(error?.response?.data?.message ? error?.response?.data?.message : 'Internal server error', "E");
                }
            })
        }
    },

    async getApplicationList({ commit, dispatch }, id) {
        commit("setShowLoadingOnApiCall", true);
        await api.getApplicationList(id).then(response => {
            if (response?.status === 200 && response?.data) {
                commit("setShowLoadingOnApiCall", false);
                commit('setApplicationList', response?.data);
            }
        }).catch(error => {
            if(error?.response?.status == 400 ||error?.response?.status == 401 ||error?.response?.status == 403 ||error?.response?.status == 404){
                commit("setShowLoadingOnApiCall", false);
                dispatch("ssoSignout", {root:true});
            }else if(error?.response?.status == 500){
                commit("setShowLoadingOnApiCall", false);
                Toast.showToast(error?.response?.data?.message ? error?.response?.data?.message : 'Internal server error', "E");
            }
        });
    },

    async getApplicationListByFilter({ commit, dispatch}, req) {
        commit("setShowLoadingOnApiCall", true);
        await api.getApplicationListByFilter(req).then(response => {
            if (response?.status === 200 && response?.data) {
                commit('setApplicationListByFilter', response.data);
                commit("setShowLoadingOnApiCall", false);
            }
        }).catch(error => {
            if(error?.response?.status == 400 ||error?.response?.status == 401 ||error?.response?.status == 403 ||error?.response?.status == 404){
                commit("setShowLoadingOnApiCall", false);
                dispatch("ssoSignout", {root:true});        
            }else if(error?.response?.status == 500){
                commit("setShowLoadingOnApiCall", false);
                Toast.showToast(error?.response?.data?.message ? error?.response?.data?.message : 'Internal server error', "E");
            }
        });
    },

    async submitDPApplicationForm({ commit, dispatch }, request) {
        await api.submitDPApplicationForm(request).then(response => {
            if (response?.status == 200 || response?.status == 201 && response?.data) {
                commit('setCreatedApplicationData', response?.data);
                Toast.showToast('Application submitted successfully!', 'S');
                dispatch('getDockerFileDetails',request?.fdxVersions[0]);                
            }
        }).catch(error => {
            if(error?.response?.status == 400 ||error?.response?.status == 401 ||error?.response?.status == 403 ||error?.response?.status == 404){
                dispatch("ssoSignout", {root:true});
            }else if(error?.response?.status == 500){
                commit("setShowLoadingOnApiCall", false);
                Toast.showToast(error?.response?.data?.message ? error?.response?.data?.message : 'Internal server error', "E");
            }else{
                router.push("/createNewApplication");
            }            
        })
    },

    async getApplicationDetails({ commit, dispatch }, id) {
        await api.getApplicationDetails(id).then(response => {
            if (response?.status === 200 && response?.data) {
                commit('setApplicationList', response?.data);
            }
        }).catch(error => {
            if(error?.response?.status == 400 ||error?.response?.status == 401 ||error?.response?.status == 403 ||error?.response?.status == 404){
                dispatch("ssoSignout", {root:true});
            }else if(error?.response?.status == 500){
                commit("setShowLoadingOnApiCall", false);
                Toast.showToast(error?.response?.data?.message ? error?.response?.data?.message : 'Internal server error', "E");
            }
        })
    },

    async updateApplicationStatus({ commit, dispatch }, req) {
        await api.updateApplicationStatus(req).then(res => {
            if (res?.status === 200 && res?.data) {
                if (req?.status == 'TEST_SUITE_DOWNLOADED') {
                    console.log(req?.status);
                } else if (req?.status == 'APPROVED') {
                    Toast.showToast(
                        "Application for Certification has been approved",
                        "S"
                    );
                    router.push('/home');
                } else if (req?.status == 'REJECTED') {
                    Toast.showToast(
                        "Application for Certification has been rejected",
                        "S"
                    );
                    router.push('/home');
                }
            }
        }).catch(error => {
            if(error?.response?.status == 400 ||error?.response?.status == 401 ||error?.response?.status == 403 ||error?.response?.status == 404){
                dispatch("ssoSignout", {root:true});
            }else if(error?.response?.status == 500){
                commit("setShowLoadingOnApiCall", false);
                Toast.showToast(error?.response?.data?.message ? error?.response?.data?.message : 'Internal server error', "E");
            }
        });
    },


    async downloadDockerFile({commit, dispatch}, req){
        await api.downloadDockerFile(req?.imageVersion).then(res => {
            if(res?.data !== null || res?.data !== ""){
                window.location.href = res?.data;
                dispatch("updateApplicationStatus", req);
            }
        }).catch(error => {
            if(error?.response?.status == 400 ||error?.response?.status == 401 ||error?.response?.status == 403 ||error?.response?.status == 404){
                dispatch("ssoSignout", {root:true});
            }else if(error?.response?.status == 500){
                commit("setShowLoadingOnApiCall", false);
                Toast.showToast(error?.response?.data?.message ? error?.response?.data?.message : 'Internal server error', "E");
            }
        });
    },


    async uploadPackage({ commit, dispatch }, req) {
        commit("setShowLoadingOnApiCall", true);
        await api.uploadPackage(req?.upload).then(res => {
            if (res?.status == 200 && res?.data == "Success") {
                commit("setShowLoadingOnApiCall", false);
                Toast.showToast('Test results submitted successfully', 'S');
                dispatch("getApplicationList", req?.orgId);
            }
        }).catch(error => {
            console.log("error", error);
            if(error?.response?.status == 400 ||error?.response?.status == 401 ||error?.response?.status == 403 ||error?.response?.status == 404){
                commit("setShowLoadingOnApiCall", false);
                dispatch("ssoSignout", {root:true});
            }else if(error?.response?.status == 500){
                commit("setShowLoadingOnApiCall", false);
                Toast.showToast(error?.response?.data?.message ? error?.response?.data?.message : 'Internal server error', "E");
            }
        })
    },

    async getApplicatiionDetailsById({ commit, dispatch }, id) {
        await api.getApplicationDetails(id).then(res => {
            if (res?.status == 200 || res?.status == 201 && res?.data) {
                commit('setApplicationDetails', res?.data);
                dispatch("getOrganizationDetailsById", res?.data?.imisNumber);
            }
        }).catch(error => {
            if(error?.response?.status == 400 ||error?.response?.status == 401 ||error?.response?.status == 403 ||error?.response?.status == 404){
                dispatch("ssoSignout", {root:true});
            }else if(error?.response?.status == 500){
                commit("setShowLoadingOnApiCall", false);
                Toast.showToast(error?.response?.data?.message ? error?.response?.data?.message : 'Internal server error', "E");
            }
        })
    },

    async getOrganizationDetailsById({ commit, dispatch }, id) {
        if (id) {
            await api.getOrganization(id).then(response => {
                if (response?.status === 200 && response?.data) {
                    commit('setApplicationUserDetails', response?.data);
                }
            }).catch(error => {
                if(error?.response?.status == 400 ||error?.response?.status == 401 ||error?.response?.status == 403 ||error?.response?.status == 404){
                    dispatch("ssoSignout", {root:true});
                }else if(error?.response?.status == 500){
                    commit("setShowLoadingOnApiCall", false);
                    Toast.showToast(error?.response?.data?.message ? error?.response?.data?.message : 'Internal server error', "E");
                }
            })
        }
    },

    async getDockerFileDetails({ commit, dispatch }, version) {
        await api.getDockerFileDetails(version).then(res => {
            if (res?.status == 200 || res?.status == 201 && res?.data) {
                commit('setDockerFileDetails', res?.data);
                router.push("/downloadDockerFile");
            }
        }).catch(error => {
            if(error?.response?.status == 400 ||error?.response?.status == 401 ||error?.response?.status == 403 ||error?.response?.status == 404){
                dispatch("ssoSignout", {root:true});
            }else if(error?.response?.status == 500){
                commit("setShowLoadingOnApiCall", false);
                Toast.showToast(error?.response?.data?.message ? error?.response?.data?.message : 'Internal server error', "E");
            }
        })
    },

    async getViewTestResultsById({ commit, dispatch }, id) {
        await api.getViewResults(id).then(res => {
            if (res?.status == 200 || res?.status == 201 && res?.data) {
                commit('setViewUploadedResults', res?.data);
            }
        }).catch(error => {
            if(error?.response?.status == 400 ||error?.response?.status == 401 ||error?.response?.status == 403 ||error?.response?.status == 404){
                dispatch("ssoSignout", {root:true});
            }else if(error?.response?.status == 500){
                commit("setShowLoadingOnApiCall", false);
                Toast.showToast(error?.response?.data?.message ? error?.response?.data?.message : 'Internal server error', "E");
            }
        })
    },

    async updatePaymentStatus({ commit, dispatch }, req) {
        commit("setShowLoadingOnApiCall", true);
        await api.updatePaymentStatus(req).then(res => {
            if (res?.status === 200 && res?.data) {
                commit("setShowLoadingOnApiCall", false);
                if (req?.status == 'PAYMENT_PENDING') {
                    Toast.showToast(
                        "Fee payment status updated successfully",
                        "S"
                    );
                } else {
                    Toast.showToast(
                        "Fee payment status updated successfully",
                        "S"
                    );
                }
                dispatch("getApplicationListByFilter", req?.filter);
            }
        }).catch(error => {
            if(error?.response?.status == 400 ||error?.response?.status == 401 ||error?.response?.status == 403 ||error?.response?.status == 404){
                commit("setShowLoadingOnApiCall", false);
                dispatch("ssoSignout", {root:true});
            }else if(error?.response?.status == 500){
                commit("setShowLoadingOnApiCall", false);
                Toast.showToast(error?.response?.data?.message ? error?.response?.data?.message : 'Internal server error', "E");
            }
        })
    },
}

export default {
    state: vueState,
    getters,
    mutations,
    actions
}