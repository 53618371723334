import { createApp } from 'vue';
import App from './App.vue';

import router from './router/Router';
import store from './store';
import vBlur from 'v-blur';

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css'

import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";

const app = createApp(App);

app.config.productionTip = false
app.use(router);
app.use(store);
app.use(vBlur);
app.use(BootstrapVue);
app.use(IconsPlugin);
app.component("multiselect", Multiselect);

app.mount('#app')
