<template>
  <div class="text-center">
    <!-- <div class="ucimg">Site under construction. All data is for illustrative purposes only.</div> -->
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.ucimg {
  background-color: #0f8575;
  color: white;
  padding-top: 30px;
}
</style>