// import Vue from 'vue';
// import Vuex from 'vuex';
import Auth from './module/Auth';
import Company from './module/Company';
import VuexPersistence from 'vuex-persist';

import { createStore } from 'vuex'

const vuexLocal = new VuexPersistence({
    storage: window.localStorage
  })

//Vue.use(Vuex);

export default createStore({

    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        Auth,
        Company
    },
    // plugins: [createPersistedState()],
       plugins: [vuexLocal.plugin],
})