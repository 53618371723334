// import Vue from "vue";
// import VueToast from "vue-toast-notification";
import 'vue-toast-notification/dist/theme-sugar.css';

import {useToast} from 'vue-toast-notification';


// Vue.use(VueToast);

const $toast = useToast();

export default {
  showToast(msg, type) {
    $toast.clear();
    if (type === "S") {
      $toast.success(msg, {
        duration: 5000,
        dismissible: true,
        position: "top-right"
      });
    }
    if (type === "E") {
      $toast.error(msg, {
        duration: 5000,
        dismissible: true,
        position: "top-right"
      });
    }
  }
};
