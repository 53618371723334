import axios from './AxiosInterceptors';
import store from '../store';
import Toast from "../components/Notifications/Toast";

// const URL = window.location.origin;
const ROOT_URL = `https://certificationapi-staging.financialdataexchange.org/certification-portal`;

export default {
    ROOT_URL,
    ssoAuthentication: function () {
        window.location.href = ROOT_URL+'/oauth2/authorization';
    },
    ssoSignout: function (token, req) {
        store.commit('setTokenStartTime', null);
        store.commit('setToken', null);
        store.commit('setTokenStatus', false);
        store.commit('setAuthCode', null);
        store.dispatch('updateToken', null);
        store.commit('setUser', null),
        store.commit('setUserRole', 'anonymous'),
        store.commit('setCallbackURL', null);
        if(req === true) {
            Toast.showToast('Signed out successfully!', 'S');
        }
        else {
            Toast.showToast('Your session has expired due to inactivity. Please log in again to continue.', 'E');
        }
        setTimeout(()=>{
            window.location.href = URL;
        },3000)
    },
    getAccessToken: function (authCode) {
        return axios.get(`${ROOT_URL}/auth/token/${authCode}`);
    },
    refreshToken: function () {
        return axios.get(`${ROOT_URL}/auth/refreshtoken/`);
    },
    getUserInfo: function () {
        return axios.get(`${ROOT_URL}/auth/userinfo`);
    },
    getOrganization: function (id) {
        return axios.get(`${ROOT_URL}/organizations/${id}`);
    },
    getApplicationList: function (id) {
        return axios.get(`${ROOT_URL}/certification/imis/${id}`);
    },
    getApplicationDetails: function (id) {
        return axios.get(`${ROOT_URL}/certification/${id}`);
    },
    getDockerFileDetails: function (version) {
        return axios.get(`${ROOT_URL}/certification/docker-info?version=${version}`);
    },
    submitDPApplicationForm: function (request) {
        return axios.post(`${ROOT_URL}/certification`, request);
    },
    downloadDockerFile: function (imgVersion) {
        return axios.get(`${ROOT_URL}/certification/download-docker?imageVersion=${imgVersion}`
        );
    },
    updateApplicationStatus: function (request) {
        return axios.put(`${ROOT_URL}/certification/${request.id}/status`, null, /*{
        return axios.post(`${ROOT_URL}/certification/create-package`, null, {
            responseType: 'blob'
        } ,*/ {
                params: {
                    status: request.status
                }
            });
    },
    uploadPackage: function (req) {
        let formdata = new FormData();
        formdata.append('package', req.file);
        return axios.post(`${ROOT_URL}/certification/${req.id}/upload-package`, formdata);
    },
    getViewResults: function (id) {
        return axios.get(`${ROOT_URL}/certification/${id}/reports`);
    },
    updatePaymentStatus: function (request) {
        return axios.put(`${ROOT_URL}/certification/${request.id}/status`, null, {
            params: {
                paymentStatus: request.status
            }
        });
    },
    getApplicationListByFilter: function (req) {
        return axios.get(`${ROOT_URL}/certification`, {
            params: {
                page: req?.page,
                size: req?.size,
                sort: req?.sort,
                status: req?.status,
                paymentStatus: req?.pStatus,
                organizationNames: req?.orgName,
            }
        });
    }







}