import axios from 'axios';
import Store from "../store";

// Step-1: Create a new Axios instance with a custom config.
// The timeout is set to 10s. If the request takes longer than
// that then the request will be aborted.


const AxiosInterceptors = axios.create({

    // baseURL: `https://example.com/api`,
    //headers: { 'Authorization': "Bearer " + access_token }
});

// Step-2: Create request, response & error handlers
// const requestHandler = request => {

//     // Token will be dynamic so we can use any app-specific way to always   
//     // fetch the new token before making the call



//     if (Store && Store.getters.getToken) {
//         var token = Store.getters.getToken;
//         var access_token = JSON.parse(token).access_token;
//         request.headers.Authorization = "Bearer " + access_token;
//     }
//     return request;
// };

// const responseHandler = response => {

//     return response;
// };

// const requestErrorHandler = error => {

//     return Promise.reject(error);
// };
// const errorHandler = error => {

//     return Promise.reject(error);
// };

const isTokenExperied = () => {

    if (Store.getters.getRefreshTokenStartTime) {
        var diff = new Date().getTime() - Store.getters.getRefreshTokenStartTime;
        return diff > 600000 ? true : false;
    } else {
        return false
    }

}
const refreshToken = () => {
    if (Store && Store.getters.getToken) {
        Store.dispatch('refreshToken');
    }
}
// Step-3: Configure/make use of request & response interceptors from Axios
// Note: You can create one method say configureInterceptors, add below in that,
// export and call it in an init function of the application/page.

AxiosInterceptors.interceptors.request.use(
    async config => {
        var token = Store.getters.getToken;
        if (config.url.includes('refreshtoken')) {
            var refresh_token = JSON.parse(token).refresh_token;
            config.url = config.url + refresh_token;
            return config;
        } else {
            if (token && isTokenExperied() === true) {
                refreshToken();
            }
            token = Store.getters.getToken;
            if (Store && token) {
                var access_token = JSON.parse(token).access_token;
                config.headers.Authorization = "Bearer " + access_token;
                return config;
            } else {
                return config;
            }
        }
    },
    error => {
        Promise.reject(error)
    });

// Response interceptor for API calls
AxiosInterceptors.interceptors.response.use((response) => {
    return response
}, async function (error) {

    return Promise.reject(error);
});
// Step-4: Export the newly created Axios instance to be used in different locations.
export default AxiosInterceptors;