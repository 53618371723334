import api from "../../api/FdxCertificationPortalApi";
import router from '../../router/Router';
import Toast from "../../components/Notifications/Toast"

const vueState = {
    userRole: 'anonymous',
    user: null,
    showRREPopup: false,
    showREPopup: false,
    popup: false,
    popupimis: false,
    becomemember: false,
    refreshTokenExpired: false,
    authCode: null,
    token: null,
    tokenStartTime: null,
    callbackURL: null,
}

const getters = {
    getRefreshTokenStartTime(state) {
        return state.tokenStartTime;
    },
    getUserRole(state) {
        return state.userRole;
    },
    getPopup(state) {
        return state.popup
    },
    getPopupimis(state) {
        return state.popupimis
    },
    getAuthCode(state) {
        return state.authCode
    },
    getToken(state) {
        return state.token
    },
    getCallbackURL(state) {
        return state.callbackURL
    },
    getUser(state) {
        return state.user
    }
}

const mutations = {
    setUserRole(state, userRole) {
        state.userRole = userRole;
    },
    setTokenStatus(state, refreshTokenExpired) {
        state.refreshTokenExpired = refreshTokenExpired;
    },
    setTokenStartTime(state, tokenStartTime) {
        state.tokenStartTime = tokenStartTime;
    },
    setShowRREPopup(state, showRREPopup) {
        state.showRREPopup = showRREPopup;
    },
    setShowREPopup(state, showREPopup) {
        state.showREPopup = showREPopup;
    },
    setUser(state, user) {
        state.user = user;
    },
    setPopup(state, popup) {
        state.popup = popup;
    },
    setPopupimis(state, popupimis) {
        state.popupimis = popupimis;
    },
    setAuthCode(state, authCode) {
        state.authCode = authCode;
    },
    setToken(state, token) {
        state.token = token
    },
    setCallbackURL(state, callbackURL) {
        state.callbackURL = callbackURL
    }
}

const actions = {
    updateUserRole({ commit }, userRole) {
        commit('setUserRole', userRole);
    },
    updateUserInfo({ commit }, userInfo) {
        commit('setUser', userInfo);
    },
    updateToken({ commit }, token) {
        commit('setToken', token);
    },
    updateAuthcode({ commit }, authcode) {
        commit('setAuthCode', authcode);
    },
    updatePopup({ commit }, popup) {
        commit('setPopup', popup)
    },
    ssoAuthentication() {
        api.ssoAuthentication();
    },
    ssoSignout({ rootState }, req) {
        api.ssoSignout(rootState?.Auth?.token, req);
    },

    getAccessToken({ commit, dispatch }, token) {
            commit('setTokenStartTime', new Date().getTime());
            commit('setToken',JSON.stringify( token));
            commit('setTokenStatus', true);
            dispatch('getUserInfo');
    },

    async refreshToken({ commit, rootState }) {
        await api.refreshToken(rootState.Auth.token).then(response => {
            if (response?.status == 200 && response?.data) {
                commit('setTokenStartTime', new Date().getTime());
                commit('setTokenStatus', true);
                commit('setToken', JSON.stringify(response.data));
            }
        }).catch(function (error) {
            commit('setTokenStatus', false);
            commit("setShowLoadingOnApiCall", false);
            if (error?.response) {
                if (error?.response?.status === 400) {
                    router.push("/login");
                }
            }
        })
    },

    getUserInfo({ commit, rootState }) {
        api.getUserInfo().then(response => {
            if (response.status === 200 && response.data) {
                if (response.data.userLogo) {
                    response.data.userLogo = "data:image/png;base64," + response.data.userLogo;
                }

                var user = response.data;
                user.paymentStatus = false;
                var userRole = "";
                if (response.data.memberType === "STAFI") {
                    userRole = "admin";
                } else if (response.data.memberType === "REGIL" || response.data.memberType === "REGIV" ||
                    response.data.memberType === "CONST" || response.data.memberType === "CONSU" || response.data.memberType === "CONTI") {
                    if (response.data.memberType === "CONST" || response.data.memberType === "CONSU" || response.data.memberType === "CONTI") {
                        user.paymentStatus = true;
                    } else {
                        user.paymentStatus = false;
                    }
                    userRole = "member";
                } else if (response.data.memberType === "NCONM" || response.data.memberType === "NCONP") {
                    userRole = "non-member";
                    user.paymentStatus = false;
                } else {
                    userRole = "anonymous";
                    user.paymentStat.us = false;
                }
                localStorage.setItem("user", JSON.stringify(user));
                commit('setUser', JSON.stringify(user));
                commit('setUserRole', userRole);
                if (rootState.Auth.callbackURL) {
                    //router.push(rootState.Auth.callbackURL);
                    if (userRole == 'admin') {
                        router.push("/home");
                    } else {
                        router.push("/");
                    }

                } else {
                    if (userRole == 'admin') {
                        router.push("/home");
                    } else {
                        router.push("/");
                    }

                }
            }
        }).catch(err => {
            console.log(err)
            Toast.showToast('Error occured while getting user Details from IMIS', 'E');
            // if (userRole == 'admin') {
            //     router.push("/home");
            // } else {
            //     router.push("/");
            // }
        })
    },

}

export default {
    state: vueState,
    getters,
    mutations,
    actions
}